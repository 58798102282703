import React from 'react'
import './homepage_web.css'

import Contact from '../../components/homepage-contact/homepage_contact.js'

import hero_homepage from '../../../static/img/hero_homepage_web.svg'
import contact_round_tag from '../../../static/img/contact_round_tag.svg'
import logo from '../../../static/img/logo.svg'
import menu from '../../../static/img/menu.svg'

export default class HomepageWeb extends React.Component {
  constructor() {
    super()
    this.state = {
      contactVisible: false,
    }
    this.toggleContact = this.toggleContact.bind(this)
  }

  toggleContact = () => {
    this.setState({ contactVisible: !this.state.contactVisible })
  }

  render() {
    return (
      <article className="homepageWeb">
        <div className="homepageWeb__nav">
          <img src={logo} className="homepageWeb__nav__logo" />
          <img
            src={menu}
            className="homepageWeb__nav__hamburger unselectable"
            alt="Hamburger menu"
          />
        </div>
        <div className="homepageWeb__background">
          <img
            src={contact_round_tag}
            className="homepageWeb__contactImg unselectable"
            alt="Contact button"
            onClick={() => {
              this.toggleContact()
            }}
          />
        </div>
        <img
          src={hero_homepage}
          className="homepageWeb__hero unselectable"
          alt="Goedgrutter logo"
          onClick={() => {
            window.open(
              'https://ddw.nl/nl/programma/5666/goedgrutter',
              '_blank'
            )
          }}
        />
        <p className="homepageWeb__text__main">
          <b>Welkom bij Goedgrutter</b>
          <br />
          De supermarkt die je <br />
          verleidt gezond te kopen
        </p>

        <p className="homepageWeb__text__secondary">
          <b>Grootgrutter - </b> zelfstandig naamwoord
          <br />
          <b>Grote kruidenier, supermarkt</b>
        </p>

        <Contact
          toggleVisibility={this.toggleContact}
          visibility={this.state.contactVisible}
        />
      </article>
    )
  }
}
