import React from 'react'
import './homepage_contact.css'
import logo from '../../../static/img/logo.svg'
import menuClose from '../../../static/img/menu_close.svg'
import mailButton from '../../../static/img/mail_round_tag.svg'
import linkedinLogo from '../../../static/img/linkedin_logo.svg'

const Contact = ({ toggleVisibility, visibility }) => {
  const toggledClassName = visibility
    ? 'contact contact--visible'
    : 'contact contact--invisible'
  return (
    <article className={toggledClassName}>
      <div className="contact__inner">
        <div className="contact__inner__background">
          <img
            className="contact__inner__background__button unselectable"
            src={mailButton}
            onClick={() => {
              //TODO Juist email adres toevoegen
              document.location.href =
                'mailto:info@goedgrutter.nl?subject=Contact via Goedgrutter.nl'
            }}
          />
        </div>
        <div className="contact__inner__header">
          <img
            className="contact__inner__header__logo unselectable"
            src={logo}
            alt="Goedgrutter logo"
          />
          <img
            className="contact__inner__header__close unselectable"
            src={menuClose}
            alt="Close button"
            aria-label="Close"
            onClick={toggleVisibility}
          />
        </div>
        <h1 className="contact__inner__title">
          Neem contact <br /> met ons op
        </h1>
        <div className="contact__inner__contactinfo">
          <div
            className="contact__inner__contactinfo__item"
            onClick={() => {
              window.open(
                'https://nl.linkedin.com/in/denice-zwiers-6a9610a2',
                '_blank'
              )
            }}
          >
            <img
              className="contact__inner__contactinfo__item__img unselectable"
              src={linkedinLogo}
            />
            <p className="contact__inner__contactinfo__item__txt">
              Denice <br />
              Zwiers
            </p>
          </div>
          <div
            className="contact__inner__contactinfo__item"
            onClick={() => {
              window.open(
                'https://nl.linkedin.com/in/maddy-ekkelkamp-99278977',
                '_blank'
              )
            }}
          >
            <img
              className="contact__inner__contactinfo__item__img unselectable"
              src={linkedinLogo}
            />
            <p className="contact__inner__contactinfo__item__txt">
              Maddy <br />
              Ekkelkamp
            </p>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Contact
