import React from 'react'

import HomepageWeb from '../components/homepage-web/homepage_web.js'
import HomepageApp from '../components/homepage-app/homepage_app.js'

import Seo from '../components/seo'

import qs from 'qs'

class RootIndex extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      isServer: true
    }
  }

  componentDidMount() {
    this.setState({
      isServer: false
    })
  }
  

  render() {
    const { isServer } = this.state
    const location = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).location

    // Check if we are pre-rendering on server side.
    // This caused issue with svg src mismatches. 
    if (isServer){
      return null; 
    }

    if (location === 'app'){
      return (
      <>
        <Seo/>
        <HomepageApp />
      </>
      )
    }

    return (
    <>
      <Seo/>
      <HomepageWeb />
    </>
    )
  }
}

export default RootIndex