import React from 'react'
import './homepage_app.css'

import BarcodeReader from 'react-barcode-reader'

import hero_homepage from '../../../static/img/hero_homepage_app.svg'
import scanner_round_tag from '../../../static/img/scanner_round_tag.svg'
import { navigate } from '../../functions/utils'

export default class HomepageApp extends React.Component {
  constructor(props){
    super(props)
 
    this.handleScan = this.handleScan.bind(this)
  }

  handleScan(data){
    navigate(data, true); 
  }

  render() {
    return (
      <article className="homepage">
        <BarcodeReader
          onScan={this.handleScan}
          />
        <div className="homepage__background">
          <img
            src={scanner_round_tag}
            className="homepage__scannerImg"
            alt="Pricetag saying scan me"
          />
        </div>
        <img
          src={hero_homepage}
          className="homepage__hero"
          alt="Text Goedgrutter"
        />
        <p className="homepage__text">
          <strong>Welkom bij Goedgrutter!</strong>
          <br />
          Scan de producten in de winkel doormiddel van de barcodescanner.
        </p>
      </article>
    )
  }
}
